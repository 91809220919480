import { auth } from "./firebase";

const base_url = process.env.REACT_APP_COMPILER_API;

export const getAuthToken = async () => {
  try {
    const user = auth.currentUser;
    if (user) {
      console.log("🔍 Getting auth token for user:", user.uid);
      return await user.getIdToken(); // Retrieves the current user's auth token
    } else {
      console.log("❌ No authenticated user found when getting auth token");
      throw new Error("No authenticated user");
    }
  } catch (error) {
    console.error("❌ Error getting auth token:", error);
    return null;
  }
};

const addAuthHeader = async () => {
  const token = await getAuthToken();
  console.log("🔍 Auth token obtained:", token ? "Token present" : "No token");
  return token ? { Authorization: `Bearer ${token}` } : {};
};

const compilerService = {
  // Run code on the server (POST /run-code)
  runCode: async ({ sessionId }) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        ...(await addAuthHeader()), // Await here to resolve the Promise
      };

      const response = await fetch(`${base_url}run-code`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ sessionId }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.text(); // Assuming the response is a simple text message
      return data;
    } catch (error) {
      console.error('Error running code:', error);
      throw error;
    }
  },

  // Create a new session (POST /sessions)
  createSession: async ({ language, title, description, files = [] }) => {
    try {
      console.log("🔍 API: Creating session with params:", { language, title, description, fileCount: files.length });
      const headers = {
        'Content-Type': 'application/json',
        ...(await addAuthHeader()),
      };

      console.log("🔍 API: Sending createSession request to:", `${base_url}sessions`);
      const response = await fetch(`${base_url}sessions`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ language, files, title, description }),
      });

      console.log("🔍 API: Create session response status:", response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error("❌ API: Create session failed:", errorText);
        throw new Error(`Error: ${response.statusText} - ${errorText}`);
      }

      const data = await response.json(); // The created session object
      return data;
    } catch (error) {
      console.error("❌ API: Error creating session:", error);
      throw error;
    }
  },

  // Fetch a session by ID (GET /sessions/:id)
  getSession: async (id) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        ...(await addAuthHeader()),
      };

      const response = await fetch(`${base_url}sessions/${id}`, {
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json(); // The session object
      return data;
    } catch (error) {
      throw error;
    }
  },

  // Update an existing session (PUT /sessions/:id)
  updateSession: async (id, { language, content, filename, title = null, description = null }) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        ...(await addAuthHeader()),
      };

      const response = await fetch(`${base_url}sessions/${id}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify({ language, content, filename, title, description }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json(); // The updated session object
      return data;
    } catch (error) {
      console.error('Error updating session:', error);
      throw error;
    }
  },

  // Fetch all sessions with pagination (GET /sessions)
  getSessions: async ({ page = 1, limit = 10 , search = ''}) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        ...(await addAuthHeader()),
      };

      const response = await fetch(`${base_url}sessions?page=${page}&limit=${limit}&search=${search}`, {
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json(); // Sessions and total count
      return data;
    } catch (error) {
      console.error('Error fetching sessions:', error);
      throw error;
    }
  },

  // Delete a session by ID (DELETE /sessions/:id)
  deleteSession: async (id) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        ...(await addAuthHeader()),
      };

      const response = await fetch(`${base_url}sessions/${id}`, {
        method: 'DELETE',
        headers,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      return true; // Successful deletion
    } catch (error) {
      console.error('Error deleting session:', error);
      throw error;
    }
  },

  changeLanguage: async (id, language) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        ...(await addAuthHeader()),
      };

      const response = await fetch(`${base_url}sessions/language/${id}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify({ language }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json(); // The updated session object
      return data;
    } catch (error) {
      console.error('Error changing language:', error);
      throw error;
    }
  },
  
  // Get user by ID (GET /users/:id)
  getUser: async (id) => {
    try {
      console.log("🔍 API: Fetching user data for ID:", id);
      const headers = {
        'Content-Type': 'application/json',
        ...(await addAuthHeader()),
      };

      console.log("🔍 API: Sending getUser request to:", `${base_url}users/${id}`);
      const response = await fetch(`${base_url}users/${id}`, {
        method: 'GET',
        headers,
      });

      console.log("🔍 API: Get user response status:", response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error("❌ API: Get user failed:", errorText);
        throw new Error(`Error: ${response.statusText} - ${errorText}`);
      }

      const data = await response.json(); // The user object
      console.log("✅ API: User data fetched successfully");
      return data;
    } catch (error) {
      console.error("❌ API: Error fetching user:", error);
      throw error;
    }
  },
};

export default compilerService;
