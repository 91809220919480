import React, { createContext, useState, lazy, Suspense } from "react";

// Create the session context
export const SessionContext = createContext();

// Create a SessionProvider component to manage session state
export const SessionProvider = ({ children }) => {
    /*
    Example session object:
    {
        "sessionId": "75e74f08-be98-4d89-b02c-3eb4c358e6d2",
        "files": [
            {
            "filename": "index.javascript",
            "id": "e9598b53-59f4-4741-b30c-62effd42b701",
            "type": "file",
            "content": "// Welcome to your new javascript session!"
            }
        ],
        "updatedAt": "2025-01-23T16:54:51.965Z",
        "userId": "6oV0YBFkejeFmeFVSg3ruglgNzn2",
        "createdAt": "2025-01-23T16:54:51.965Z",
        "impressions": 0,
        "description": "",
        "users": [],
        "language": "javascript",
        "title": "Javascript test",
        "_id": "75e74f08-be98-4d89-b02c-3eb4c358e6d2"
    }
        */
    const [session, setSession] = useState(null); // Add any session-specific state here
    return (
        <SessionContext.Provider value={{ session, setSession }}>
            {children}
        </SessionContext.Provider>
    );
};