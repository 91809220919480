import React, { useEffect, useState, lazy, Suspense } from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SocketProvider } from "./context/Socket.IO.Context";
import "bootstrap/dist/css/bootstrap.min.css";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./services/firebase";
import { SessionProvider } from "./context/Session.Context"; // Import the SessionProvider

const Login = lazy(() => import("./components/Login/Login"));
const ProtectedComponent = lazy(() => import("./components/protectedComponenet/ProtectedComponent"));
const Dashboard = lazy(() => import("./components/dashboard/Dashboard"));
const FullEditor = lazy(() => import("./components/fullEditor/FullEditor"));

function App() {
  const [user, setUser] = useState(null);
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setIsAuthChecked(true);
    });
    return () => unsubscribe();
  }, []);

  if (!isAuthChecked) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login user={user} />} />

          {/* Protected Routes */}
          <Route
            path="/"
            element={
              <ProtectedComponent user={user}>
                <Dashboard user={user} />
              </ProtectedComponent>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedComponent user={user}>
                <Dashboard user={user} />
              </ProtectedComponent>
            }
          />
          <Route
            path="/editor/:sessionId"
            element={
              <ProtectedComponent user={user}>
                <SocketProvider user={user}>
                  <SessionProvider>
                    <FullEditor user={user} />
                  </SessionProvider>
                </SocketProvider>
              </ProtectedComponent>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;